























































































































































































































































































































































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
//引入组件
import VueUeditorWrap from 'vue-ueditor-wrap'; //es6
import { SurveyService } from '@/services/survey-service';
import mapChooseUser from '@/views/surveyConfig/mapChooseUser.vue';
import mapChooseGroup from '@/views/surveyConfig/mapChooseGroup.vue';
import uploadOpenDialog from '@/components/surveyUpload/uploadOpenDialog.vue';

import { statisticsApi } from '@/services/statistics-service';
import { cloneDeep } from 'lodash';
import { ElTable } from 'element-ui/types/table';
//新的接口 换这里

@Component({
  components: {
    VueUeditorWrap,
    // wechatImageText,
    uploadOpenDialog,
    mapChooseUser,
    mapChooseGroup

  }
})
export default class addSurvey extends Vue {


  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    // serverUrl: 'https://qa.wecomfoundation.novartis.com.cn/api/application/ueditor/config',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/ueditor/',
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
      /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public title: string = '远程助手发布';
  public buttonLoading: boolean = false;
  public dialogVisible: boolean = false;
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0
  public userTitle: String = '选择用户';
  public totalNum: number = 0;//总个数
  public noNum: number = 0;//无效个数
  public useNum: number = 0;//有效个数
  public userIdArr: string = '';//默认预览名单输入框内容
  public noNumshow: Boolean = false;
  public userVisible: boolean = false;
  public selectUserVisible: boolean = false;

  public userValue: any = [];
  public userDataList: any = [];
  public multipleSelection: any = [];
  public newUserDataList: any = [];
  public currentPageDatas: any = [];
  public currentDatas: any = [];
  public sourceDatas: any = [];
  public userDataList2: any = [];
  public groupVisible: boolean = false;
  public groupValue: any = [];
  public isHospitalDoctorList: any = [{"id":1,"title":'目标医院'},{"id":0,"title":'目标医生'} ];
  public groupDataList: any = [];
  public selectId: any = Number;
  public groupTitle: String = '选择用户组';
  public imgUrl2: String = '';
  public forId: String = '';
  public labelPosition: String = 'top';
  public chooseId: String = '';
  public chooseUserId: String = '';
  public chooseGroupId: String = '';
  public userCode: String = '';
  public rightItems: any = [];
  public groupRightItems: any = [];
  public selectUserUl: any = {
    'pointer-events': ''
  };
  public tagObj: any = {
    'type': 'success',
    'closable': true
  };

  public pickerOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
    // 禁用日期
    // selectableRange: `${Date().split(" ")[4]} - 23:59:59`, // 打开默认当前时间
  };
  public deadlineTimeOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
    // 禁用日期
    // selectableRange: `${Date().split(" ")[4]} - 23:59:59`, // 打开默认当前时间
  };
  private userGroupingList: any[] = [
    {
      'id': 1,
      'title': '日'
    },
    {
      'id': 2,
      'title': '周'
    },
    {
      'id': 3,
      'title': '月'
    },
    {
      'id': 4,
      'title': '年'
    }
  ];
  public formInfo: any = {

    typeName: null,
    userGroupingList: [],
    isUserGroups: 2,
    time: [],
    releaseTime: ''

  };
  public formInline: any = {

    val: null


  };

  public userParams: any = { //查询参数

    userPostName: '',
    pages: 1,
    size: 5,
    current: 1,
    total: 0
  };
  private userTableData: any[] = [];
  public form: any = {
    id: null,
    title: '',// 标题
    subtitle: '',// 副标题
    isUserGroups: 0,// 是否是用户组 0岗位，1用户组
    //postUserList:[],// 人员范围
    userPosts: [],//用户
    isHospitalDoctor: [],
    surveyUserGroupings: [],//人员范围用户组
    typeDictionaryName: '',// 问卷类型名字
    surveyCoverUrl: '',//  问卷封面url
    // graphicAddress: '',// 预览地址
    richText: '',// 内容详情
    questionnaireId: '',//问卷id（问卷星id）
    questionnaireUrl: '',//问卷url（问卷星url）为null需要id自行查找
    publishStatus: 0,//0已保存 1已发布 2已下架
    deadlineTime: null,//截止时间
    ownerCreateUser: '',//Owner
    isCirculate: 0,//job是否可循环 0否 1是
    startTime: null,//job开始时间
    endTime: null,//job结束时间
    surveyCron: '',//job表达式
    deadlineDay: '',//几天后截止
    contentTitle: '',//内容标题
    createUser: '',
    surveyAttachments: [],
    isTop: 0,
    sequential_numbers: null,
    isRelease: 0

  };
  public fileList: any[] = [];
  public timer: any = null;
  public actionUrl: any = '';
  public formData: any = [];
  public backgroud1: number = 0; // 0否，1是
  public spicurlAlt: String = '';
  public createTime: any = '';
  public disabledTosend: Boolean = false;
  public disabledisUserGroups: Boolean = false; //可见范围 默认可编辑
  public userQueryParams: any = { //查询参数
    userPostName: '',
    userPost: '',
    email: '',
    pages: 1,
    size: 10,
    current: 1,
    total: 0
  };
  public groupQueryParams: any = { //查询参
    userGroupingName: '',
    pages: 1,
    size: 100000,
    current: 1,
    total: 0

  };
  public querySend: any = {
    channelId: ''
  };

  public rules: any = {

    title: [
      { required: true, message: '请输入标题', trigger: 'blur' }
    ],
    name: [
      { required: true, message: '请输入素材名称', trigger: 'blur' }
    ],
    // userListss: [
    //   { required: true, message: '请输入人员范围', trigger: ['change', 'blur'] }
    // ],

    typeDictionaryId: [
      { required: true, message: '请输入远程助手类型', trigger: 'blur' }
    ],

    // contentTitle: [
    //   { required: true, message: '请输入详情内容标题', trigger: 'blur' }
    // ],
    questionnaireId: [
      { required: true, message: '请输入问卷星ID', trigger: 'blur' }
    ],
    questionnaireUrl: [
      { required: true, message: '请输入问卷星URL', trigger: 'blur' }
    ],
    isUserGroups: [
      { required: true, message: '请选择人员范围', trigger: ['change', 'blur'] }],
    // surveyGroupingsList: [
    //   { required: true, message: '请选择人员组范围', trigger: ['blur', ] }],

    typeName: [
      { required: true, message: '请输入类型名称', trigger: 'blur' }
    ],
    releaseTime: [
      { required: true, message: '请选择发布开始时间', trigger: 'blur' }
    ],
    ownerCreateUser: [
      { required: true, message: '请输入Owner', trigger: 'blur' }
    ]

  };
  public uploadTitle: String = '附件上传';
  public uploadVisible: boolean = false;//附件上传显示弹框
  public activeName: String = 'first';
  public seen: Boolean = false;
  public chooseFlag: any = '';//鼠标移上去的索引
  public type: any = ''; //判断是修改还是添加
  public id: any = '';
  public imageType: String = ''; //判断是封面还是图文列表/历史消息 封面
  public userFormVisible: boolean = false;//用户人员范围
  public groupFormVisible: boolean = false;//用户组人员范围
  public readonly: boolean = true;
  public titles: String = '选择图文素材';
  public agentDisable: Boolean = false;//专栏、应用是否可选
  public loading: boolean = false;
  public articleIds: any[] = []; //存储之前的id
  public articleType: any[] = []; //存储类型
  private applications: any[] = []; //存储专栏
  private imageList: any[] = []; //存储图文集合
  private sendMessageList: any[] = []; //存储群发消息分组
  @Inject(CacheService) private cacheSvc!: CacheService;
  private userInfo: any = this.cacheSvc.get('user');
  @Inject(SurveyService) private surveyService!: SurveyService;
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;


  public async created(): Promise<void> {
    this.groupQueryParams.userCode = this.userInfo.employeeId;
    this.form.createUser = this.userInfo.email;
    this.form.ownerCreateUser = this.userInfo.email;
    const currentTime = new Date().getTime();
    this.createTime = moment(currentTime).format('YYYY-MM-DD HH:mm');

    this.type = localStorage.getItem('surveyType') || '';


    localStorage.removeItem('res');

    //问卷类型
    await this.applicationsList();
    await this.getUserList();
    await this.getGroupList();

    if (this.type != '000') { //代表是修改
      this.id = this.type; //拿到主图文id
      await this.articleGet();
      this.agentDisable = true;

    } else { //添加的话默认push数据
      this.articleIds.push('0');
      this.imageList.push(this.form);
    }
    //this.form.id = this.$route.query.detailId;
    this.formData = [];
    this.fileList = [];

    this.actionUrl = document.location.origin + '/wecom-survey-api/survey/attachment/batch/upload';
    //this.actionUrl = 'https://dev.wecomfoundation.novartis.com.cn/wecom-survey-api/survey/attachment/batch/upload';
  }

  //选择用户可见范围
  public async userChoose() {
    this.userVisible = true;
  }

  public groupChoose() {
    this.groupVisible = true;
  }

  public formatDat(value: any) {
    if (value) {
      return new Date(value).toLocaleString();
    }
  }
  public formatDat2(value: any) {
    if (value) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }


  //获取问卷类型
  public async applicationsList(): Promise<void> {
    const res = await this.surveyService.applicationsList();
    this.applications = res;

  }

  public reset() {
    this.$router.push('/surveyQuestionnaire');
  }


  //调起图片弹框
  public addPicture(types: any) {
    (this.$refs.childDialog as uploadOpenDialog).upImage();
    this.imageType = types;
    console.log(types, 'types');
  }


  public typeDictionaryIdChange(value: any) {
    var obj = this.applications.find((item) => item.id === value);
    this.selectId = obj.isVr;
    this.form.richText = '';
  }

  public addFile(types: any) {
    this.uploadVisible = true;
  }


  //得到子组件传过来的值
  public getChild(val: any): void {
    this.$nextTick(() => {
      if (this.imageType == '1') {
        this.form.surveyCoverUrl = val.src;
        this.imgUrl2 = val.src;
      }
      if (this.imageType == '2') {
        this.form.surveyCoverUrl = val.src;
        this.spicurlAlt = val.alt;
        this.imgUrl2 = val.src;

      }
    });
  }

  //查询详情
  public async articleGet(): Promise<void> {
    const res = await this.surveyService.articleGet({ id: this.id });
    this.form = res;
    if (this.form.surveyAttachments) {
      this.form.surveyAttachments = res.surveyAttachments.map(
        (i: any) => {
          return {
            ...i,
            name: i.attachmentName,
            url: i.attachmentUrl,
            id: i.id
          };
        });
    } else {
      this.form.surveyAttachments = [];
    }
    this.selectId = this.form.isVr;
    if (this.form.publishStatus == 1 || this.form.publishStatus == 4 || this.form.publishStatus == 3) {
      this.disabledTosend = true;
      this.selectUserUl = {
        'pointer-events': 'none',
        'background-color': '#F5F7FA',
        'border-color': '#E4E7ED',
        'color': '#C0C4CC',
        'cursor': 'not-allowed'
      };
      this.tagObj = {
        'type': 'info',
        'closable': false
      };
    }
    this.form.historyUrl = `${location.origin}/wecom-mfa-front/#/productMap?productMapId=` + this.form.id + '&type=1&lookCont=';
    this.imgUrl2 = res.surveyCoverUrl;
    if (this.form.isUserGroups == 0) {

      this.form.userListss = res.userPosts.map((item: any) => item.userName).join(',');
      this.form.userPosts = res.userPosts;
      //this.multipleSelection = res.userPosts;
      // this.userValue = res.userPosts.map((item: any) => item.userPost);
      //this.checkData();
      setTimeout(() => {
        this.checkData();
      }, 3000);// 测试过了，比三秒短的话，还是会报错

    }
    if (this.form.isUserGroups == 1) {

      this.form.surveyGroupingsList = res.surveyUserGroupings.map((item: any) => item.userGroupingName).join(',');
      // this.form.surveyUserGroupings = res.surveyUserGroupings;

      this.groupValue = res.surveyUserGroupings.map((item: any) => item.userGroupingId);
    }
    this.form.ownerCreateUser = res.ownerUserEmails.map((item: any) => item.userEmail).join(',');

  }


  //整个页面的提交
  public submitBigForm(formName: any): void {
    var that = this;
    // const currentTime = new Date().getTime();
    // if (formName == '1') { //提交
    //
    //   this.form.richText = '';
    //   this.form.backgroud = this.backgroud1;
    // } else {
    //   this.form.lookTime = moment(currentTime).format('YYYY-MM-DD HH:mm:ss');
    //   this.form.richText = this.form.content;
    // }
    //
    // this.form.startTime = this.form.times[0];
    // this.form.endTime = this.form.times[1];
    const addRef = (this.$refs.form as any);
    // this.applications.map((item: any) => {
    //   if (item.channelAgentId == this.form.typeDictionaryId) {
    //     this.form.contentTitle = item.channelName;
    //     return;
    //   }
    // });
    let userIds: any = [];
    let groupIds: any = [];
    addRef.validate(async (valid: any) => {
      if (valid) {
        this.buttonLoading = true;
        if (this.form.isUserGroups == 0) {
           if (this.form.userPosts ==null) {
             this.form.userPosts = []
           }
          if (this.form.userPosts.length == 0) {
            this.$message({
              type: 'info',
              message: '请选择人员范围'
            });
            this.buttonLoading = false;
            return;
          }
          //}
          //   if (this.form.userPosts.length == 1) {
          //     this.form.userOpenScope = this.form.userPosts[0].id || this.form.userPosts[0].userCode;
          //   } else {
          //     this.form.userPosts.map((item: any) => {
          //       if (item.id) {
          //         userIds.push(item.id);
          //       } else if (item.userCode) {
          //         userIds.push(item.userCode);
          //       }
          //     });
          //     this.form.userOpenScope = userIds.join(',');
          //   }
          // } else {
          //   console.log("bbb")
          //   this.$message({
          //     type: 'info',
          //     message: '请输入对应字段'
          //   });
          //   return
          // }
        }
        if (this.form.isUserGroups == 1) {
          console.log('bbbbb');
          if(this.form.surveyUserGroupings == null){
            this.form.surveyUserGroupings = []
          }
          if (this.form.surveyUserGroupings.length == 0) {
            this.$message({
              type: 'info',
              message: '请选择人员组范围'
            });
            this.buttonLoading = false;
            return;
          }


          // if (this.form.surveyUserGroupings) {
          //   console.log(this.form.surveyUserGroupings, 'this.form.surveyUserGroupings');
          //   if (this.form.surveyUserGroupings.length == 1) {
          //     this.form.groupOpenScope = this.form.surveyUserGroupings[0].id || this.form.surveyUserGroupings[0].userGroupingId;
          //   } else {
          //     this.form.surveyUserGroupings.map((item: any) => {
          //       if (item.id) {
          //         groupIds.push(item.id);
          //       } else if (item.userGroupingId) {
          //         groupIds.push(item.userGroupingId);
          //       }
          //     });
          //     this.form.groupOpenScope = groupIds.join(',');
          //   }
          // } else {
          //   this.$message({
          //     type: 'info',
          //     message: '请输入对应字段'
          //   });
          //   return
          // }
        }
        let res: any = {};
        //console.log(this.type, 'this.type');
        if (this.type != '000') { //代表是修改
          console.log('修改');
          this.form.id = this.type;
          if (formName == '提交') {
            console.log('提交');
            // let forId = localStorage.getItem('res');
            // console.log(forId, 'forId');
            // if(forId){
            this.form.userLoginName = this.userInfo.fullName;
            this.form.userLoginCode = this.userInfo.employeeId;
            await this.surveyService.articleUpdate(this.form).then((res: any) => {
              if (res) {
                if (this.form.surveyAttachments) {
                  let formData = new FormData();
                  this.form.surveyAttachments.forEach((item: any) => {
                    formData.append('files', item.raw);
                  });
                  formData.append('id', res);
                  this.statisticsApi.fileBatchUpload(formData).then((res) => {
                    if (res == '上传成功') {
                      this.$message({
                        message: '修改成功',
                        type: 'success'
                      });
                      this.$router.push('/surveyQuestionnaire');
                      this.buttonLoading = false;
                    }
                  }).catch((err) => {
                    this.buttonLoading = false;
                    console.log(err, 'err');

                  });
                } else {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                  this.$router.push('/surveyQuestionnaire');
                  this.buttonLoading = false;
                }

              } else {
                this.buttonLoading = false;
              }
            }).catch((err: any) => {
              console.log(err, 'err');
              this.buttonLoading = false;
            });

          } else if (formName == '预览') {
            console.log('预览');
            this.form.graphicAddress = null;
            console.log(this.form.id, 'this.form.id');
            let paramData = {
              'id': this.form.id,
              'richText': this.form.richText
            };

          }
        } else if (this.type == '000') {
          console.log('新增');
          if (formName == '预览') {
            console.log('预览');
            let forId = localStorage.getItem('res');
            if (this.forId) {
              console.log(this.form, 'this.form');
              this.form.graphicAddress = null;
              let paramData = {
                'id': this.forId,
                'richText': this.form.richText
              };
              let res2: any = {};
              // res2 = await this.surveyService.articlePreview(paramData, '2');
              //res = await this.SurveyService.articleUpdate(this.form);
              this.form.id = this.forId;
            } else {
              //this.form.graphicAddress = `${location.origin}/wecom-mfa-front/#/productMap`;
              this.form.userLoginName = this.userInfo.fullName;
              this.form.userLoginCode = this.userInfo.employeeId;
              res = await this.surveyService.articleAdd(this.form);
              localStorage.setItem('res', JSON.stringify(res));
              this.form.id = res.id;
              this.forId = res.id;
              let paramData = {
                'id': this.form.id,
                'richText': this.form.richText
              };
              console.log(paramData, 'articleAddparamData');
              let res2: any = {};
              //res2 = await this.surveyService.articlePreview(paramData, '2');
            }
          } else if (formName == '提交') {
            console.log('提交');

            //this.form.graphicAddress = `${location.origin}/wecom-mfa-front/#/productMap`;
            //this.form.content = this.form.richText;
            // res = await this.surveyService.articleAdd(this.form);
            // console.log(res, '提交提交结果');
            // let paramData = {
            //   'id': res.id,
            //   'richText': this.form.richText
            // };
            //console.log(paramData, 'articleAddparamData');
            // 上传文件大小不能超过100MB!
            //此处是上传
            // const isLt100M = this.fileList.every(
            //   (file) => file.size / 1024 / 1024 < 100
            // );
            //
            this.form.userLoginName = this.userInfo.fullName;
            this.form.userLoginCode = this.userInfo.employeeId;
            await this.surveyService.articleAdd(this.form).then((res: any) => {
              if (res) {
                let formData = new FormData();
                this.form.surveyAttachments.forEach((item: any) => {
                  formData.append('files', item.raw);
                });
                formData.append('id', res);
                this.statisticsApi.fileBatchUpload(formData).then((res) => {
                  if (res == '上传成功') {
                    this.$message({
                      message: '添加成功',
                      type: 'success'
                    });
                    this.$router.push('/surveyQuestionnaire');
                  }
                  this.buttonLoading = false;
                }).catch((err) => {
                  console.log(err, 'err');
                  this.buttonLoading = false;
                });
              } else {
                this.buttonLoading = false;
              }
            }).catch((err: any) => {
              console.log(err, 'err');
              this.buttonLoading = false;
            });


          }
        }
      } else {
        this.buttonLoading = false;
        this.$message({
          type: 'info',
          message: '请输入对应字段'
        });
        this.buttonLoading = false;
        return false;
      }
    });
    console.log(this.form, 'submitForm.form');
  }

  public submitForm2(formName: any): void {
    this.formInfo.startTime = this.formInfo.time[0];
    this.formInfo.endTime = this.formInfo.time[1];
    const addRef = (this.$refs.formInfo as any);
    addRef.validate(async (valid: any) => {
      if (valid) {
      } else {
        this.$message({
          type: 'info',
          message: '请输入对应字段'
        });
        return false;
      }
    });
    console.log(this.formInfo, 'submitForm.formInfo');
  }

  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1);
  }

  public async handleClick(tab: any, event: any): Promise<void> {
    console.log(tab, event);
  }

  //复制
  public copyUrl() {
    var oInput = document.createElement('input');
    oInput.value = this.form.historyUrl;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand('Copy');
    this.$message({
      type: 'success',
      message: '复制成功'
    });
    oInput.remove();
  }

  selectChange(e: any): void {
    console.log(e, 'selectChange');
  }

  //用户传值处理
  public userSelectChange(e: any): void {
    console.log(e, 'userSelectChange');
    if (e == '取消') {
      console.log('aaaaaa');
      this.userFormVisible = false;
      return;
    } else if (e) {
      console.log(e, 'bbb');
      this.form.userPosts = [];
      e.forEach((item: Dict<any>) => {
        let json: any = {};
        json.id = item.userCode;
        json.userPost = item.userPost;
        json.positionLevel = item.positionLevel;
        json.userCode = item.userCode;
        json.userName = item.userName;
        json.userPostId = item.userPostId;
        this.form.userPosts.push(json);
      });
      this.userFormatData(this.form.userPosts);
    }
    this.userFormVisible = false;

    //
    // console.log(e, 'eeeeee');
    // if (e == false) {
    //   this.userFormVisible = false;
    //   this.$message({
    //     type: 'info',
    //     message: '至少选择一个'
    //   });
    //
    //   return;
    // }
    // this.form.userPosts = [];
    // e.forEach((item: Dict<any>) => {
    //   let json: any = {};
    //   json.id = item.userCode;
    //   json.userPost = item.userPost;
    //   json.positionLevel = item.positionLevel;
    //   json.userCode = item.userCode;
    //   json.userName = item.userName;
    //   json.userPostId = item.userPostId;
    //   this.form.userPosts.push(json);
    // });
    // this.userFormatData(this.form.userPosts);
    // console.log(this.form.userPosts, 'this.form.groupList');
    // this.userFormVisible = false;
  }

  //用户组传值处理
  public groupSelectChange(e: any): void {
    if (e == '取消') {
      this.groupFormVisible = false;
      return;
    } else if (e) {
      this.form.surveyUserGroupings = [];
      e.forEach((item: Dict<any>) => {
        let json: any = {};
        json.id = item.userGroupingId;
        json.userGroupingId = item.userGroupingId;
        json.userGroupingName = item.userGroupingName;
        this.form.surveyUserGroupings.push(json);
      });
      this.groupFormatData(this.form.surveyUserGroupings);
    }
    this.groupFormVisible = false;
    // console.log(e, 'eeeeee');
    // if (e == false) {
    //   this.groupFormVisible = false;
    //   this.$message({
    //     type: 'info',
    //     message: '至少选择一个'
    //   });
    //
    //   return;
    // }
    // this.form.surveyUserGroupings = [];
    // e.forEach((item: Dict<any>) => {
    //   let json: any = {};
    //   json.id = item.userGroupingId;
    //   json.userGroupingId = item.userGroupingId;
    //   json.userGroupingName = item.userGroupingName;
    //   this.form.surveyUserGroupings.push(json);
    // });
    // this.groupFormatData(this.form.surveyUserGroupings);
    // console.log(this.form.surveyUserGroupings, '用户传值处理');
    // this.groupFormVisible = false;


  }

  //应用分组数据回显处理
  public async groupFormatData(data: any) {
    console.log(data, 'groupFormatData');
    //应用分组数组回显处理
    let arr: any = [];
    for (var item = 0; item < data.length; item++) {
      arr.push(data[item].userGroupingName);
    }
    console.log(arr, '应用分组数据回显处理');
    this.$nextTick(function() {
      let list = arr.join(',');
      this.$set(this.form, 'surveyGroupingsList', list);
      console.log(list, 'surveyGroupingsList');
      this.form.surveyGroupingsList = list;
    });
  }

  //用户数据回显处理
  public async userFormatData(data: any) {
    console.log(data, 'userFormatData');

    //应用分组数组回显处理
    let arr: any = [];
    for (var item = 0; item < data.length; item++) {
      arr.push(data[item].userName);
    }
    console.log(arr, '用户数据回显处理');
    //this.$nextTick(function() {
    let list = arr.join(',');
    this.$set(this.form, 'userListss', list);
    console.log(list, 'userFormatDataList');
    this.form.userPosts = data;
    //});
  }

  // 拖拽上传
  public beforeRemove(file: any, fileList: any) {
    //this.form.surveyAttachments = fileList;
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  // 移除附件
  public upLoadRemove(file: any, fileList: any) {
    console.log(file, '移除附件');
    const res = this.surveyService.deleteSurveyFiles(file);

    if (res) {
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } else {
      this.$message({
        message: '删除失败',
        type: 'error'
      });
    }

    let tempFileList = [];
    for (var index = 0; index < this.form.surveyAttachments.length; index++) {
      if (this.form.surveyAttachments[index].name !== file.name) {
        tempFileList.push(this.form.surveyAttachments[index]);
      }
    }
    this.form.surveyAttachments = tempFileList;
  }

  // 监控上传文件列表
  public uploadChange(file: any, fileList: any) {
    console.log(fileList, '监控上传文件列表');
    let existFile = fileList.slice(0, fileList.length - 1).find((f: any) => f.name === file.name);
    if (existFile) {
      this.$message.error('当前文件已经存在!');
      fileList.pop();
    }
    this.form.surveyAttachments = fileList;
  }

  // 上传到服务器  formidable接收
  async submitUpload() {
    console.log('上传到服务器');
    // 上传文件大小不能超过100MB!
    const isLt100M = this.fileList.every(
      (file) => file.size / 1024 / 1024 < 100
    );
    let formData = new FormData();
    this.form.surveyAttachments.forEach((item: any) => {
      formData.append('surveyAttachments', item.raw);
    });
    // formData.append("parentId", this.mapValue);
    // append追加后console.log后仍为空,需要用formData.get("键")的方法获取值
    await this.statisticsApi.surveyBatchUpload(formData).then((res) => {
      if (res == '上传成功') {
        this.$message.success('上传成功');
      }
    }).catch((err) => {
      console.log(err, 'err');
    });

  }

  // 点击文件进行下载
  public downLoadFile(file: any, fileList: any) {
    var a = document.createElement('a');
    var event = new MouseEvent('click');
    a.download = file.name;
    a.href = file.url;
    a.dispatchEvent(event);
  }

  public groupTransferChange(value: any, direction: any, movedKeys: any) {
    console.log(value, 'val');
    console.log(direction, 'direction');
    console.log(movedKeys, 'movedKeys');
    if (direction === 'right') {
      this.groupRightItems = this.groupDataList.filter((item: any) => value.includes(item.key));
      console.log('右侧列表对象：', this.groupRightItems);
    }
    if (direction === 'left') {
      this.groupRightItems = this.groupDataList.filter((item: any) => value.includes(item.key));
      console.log('右侧列表对象：', this.groupRightItems);
    }
  }


  public userTransferChange(value: any, direction: any, movedKeys: any) {
    console.log(value, 'val');
    console.log(direction, 'direction');
    console.log(movedKeys, 'movedKeys');
    if (direction === 'right') {
      //userCode和userPost为空判断
      // for (let key of movedKeys) {
      //   const option = this.userDataList.find(item => item.key === key);
      //   if (!option.userCode || !option.userPost) {
      //     this.$message.error('用户Code或用户岗位不能为空，添加失败！');
      //     // 取消向右操作
      //     this.userValue = this.userValue.filter(val => val !== key);
      //   }
      // }
      this.rightItems = this.userDataList.filter((item: any) => value.includes(item.userPost));
      console.log('右侧列表对象：', this.rightItems);
    }
    if (direction === 'left') {
      this.rightItems = this.userDataList.filter((item: any) => value.includes(item.userPost));
      console.log('右侧列表对象：', this.rightItems);
    }
  }

  public userFilterMethod(query: any, item: any) {
    if (item.email || item.userPost) {

      return (
        item.email.indexOf(query) > -1 ||
        item.userPost.indexOf(query) > -1
      );

    }
    // if (!this.timer) {
    //   // 如果存在之前的定时器，先清除
    //   clearTimeout(this.timer);
    // }

    // 设置一个新的定时器，延迟 300 毫秒后执行搜索逻辑
    // this.timer = setTimeout(() => {
    //   if (item.email || item.userPost) {
    //
    //     return (
    //       item.email.indexOf(query) > -1 ||
    //       item.userPost.indexOf(query) > -1
    //     );
    //
    //   }
    // }, 1);
  }

  realFilterMethod(query: any, item: any) {
    // 这里是实际的搜索逻辑，你可以根据需要进行搜索操作
    if (item.email || item.userPost) {

      return (
        item.email.indexOf(query) > -1 ||
        item.userPost.indexOf(query) > -1
      );

    }
  }

  public groupFilterMethod(query: any, item: any) {
    return item.userGroupingName.indexOf(query) > -1;
  }

  public groupSubmit() {
    console.log(this.groupRightItems, 'this.groupRightItems');

    this.form.surveyGroupingsList = this.groupRightItems.map((item: any) => item.userGroupingName).join(',');
    this.form.surveyUserGroupings = this.groupRightItems;
    console.log(this.form.surveyGroupingsList, ' this.form.surveyGroupingsList');

    this.groupVisible = false;
  }


  public onSubmit() {
    // this.userQueryParams.userPostName = this.formInline.val;
    // this.getUserList();


    // const originalData = cloneDeep(this.userDataList);
    // console.log(this.userDataList,'this.111')
    //  console.log(this.userDataList2,'this.222')
    //
    // let userDataList2 = []; // 存储搜索结果的数组
    // userDataList2 = this.userDataList2.filter((item:any) => {
    //   // 判断邮箱或用户岗位是否包含关键词
    //   return item.email.includes(this.formInline.val) || item.userPost.includes(this.formInline.val);
    // });
    // this.userDataList = userDataList2


  }

  public getRowKeys(row: any) {
    return row.userPost;
  }

  public userSubmit() {
    if(this.form.userPosts==null){
      this.form.userPosts = []
    }
    var arr = this.form.userPosts.concat(this.multipleSelection);
    this.newUserDataList = this.uniqueArr(arr);
    // console.log(this.rightItems, 'this.rightItems');
    // this.form.userListss = this.rightItems.map((item: any) => item.userName).join(',');
    // this.form.userPosts = this.rightItems;
    // console.log(this.form.userListss, ' this.form.userListss');
    // this.userVisible = false;
    // console.log(this.newUserDataList,'this.newUserDataList')
    this.form.userListss = this.newUserDataList.map((item: any) => item.userName).join(',');
    this.form.userPosts = this.newUserDataList;
    this.userVisible = false;
  }

  public async getGroupList(): Promise<void> {

    const res = await this.surveyService.getSelectGroupList(this.groupQueryParams);

    this.groupDataList = res.records.map((item: any, index: any) => {
      return {
        ...item,
        key: item.userGroupingId,
        lable: item.userGroupingName,
        userGroupingId: item.userGroupingId
      };
    });
    this.groupQueryParams.total = res.total;
    this.groupQueryParams.pages = res.pages;
    this.groupQueryParams.size = res.size;
    this.groupQueryParams.current = res.current;
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.userQueryParams.size = size;
    //先将选中的从当前数据过滤掉
    this.currentDatas = this.currentDatas.filter(
      function(value: any) {
        console.log(value, 'value');
        //return !this.formDatas.river.includes(value.key);
      }.bind(this)
    );
    // //再将过滤好的当前数据选出指定页
    // this.currentPageDatas = this.groupFunc(val);
    // //再将选中的目标数组补给当前页变量，从而保证之前选的数据能显示
    // this.currentPageDatas = this.currentPageDatas.concat(
    //   this.sourceDatas.filter(
    //     function (val) {
    //       return this.formDatas.river.includes(val.key);
    //     }.bind(this)
    //   )
    // );


    this.getUserList();
  }

  //默认选中
  public checkData() {
    this.$nextTick(() => {
      this.userDataList.forEach((row: any) => {
        let indexs = JSON.stringify(this.form.userPosts).indexOf(row.userPost);
        if (indexs != -1) { //不等于-1 表示存在
          const checkRef = (this.$refs.multipleTable as any);
          if (checkRef) {
            checkRef.toggleRowSelection(row, true);
          }

        }
      });
    });
  }

  public async handleClose(row: any) {
    //console.log(row.userPost, 'row');
    //先对比
    // this.$nextTick(() => {
    //   this.userDataList.forEach((item: any) => {
    //     if (item.userPost === row.userPost) {
    //       const checkRef = (this.$refs.multipleTable as any);
    //       if (checkRef) {
    //         checkRef.toggleRowSelection(item, false);
    //         this.form.userPosts.forEach((item: any, index: any) => {
    //           if (item.userPost === row.userPost) {
    //             this.form.userPosts.splice(index, 1);
    //           }
    //         });
    //
    //         this.multipleSelection = this.form.userPosts;
    //       }
    //     }
    //   });
    // });
    this.form.userPosts.forEach((item: any, index: any) => {
      if (item.userPost === row.userPost) {
        this.form.userPosts.splice(index, 1);
      }
    });
    this.multipleSelection = this.form.userPosts;
    const tableRef = this.$refs.multipleTable as any;
    if (tableRef) {
      // 找到对应的行
      const rowToRemove = this.userDataList.find((item: any) => item.userPost === row.userPost);
      if (rowToRemove) {
        // 取消选中状态
        tableRef.toggleRowSelection(rowToRemove, false);
      }
    }
  }

  public uniqueArr(arr: any) {
    var result = [];
    var obj: any = {};
    for (var i = 0; i < arr.length; i++) {
      if (!obj[arr[i].userPost]) {
        result.push(arr[i]);
        obj[arr[i].userPost] = true;
      }
    }

    return result;
  }

  public handleAllChange(selection: any) {
    console.log(selection, 'selection');

  }


  public handleSelect(selection: any, row: any) {
    if(this.form.userPosts==null){
      this.form.userPosts = []
    }
    this.form.userPosts.forEach((item: any, index: any) => {
      if (item.userPost === row.userPost) {
        this.form.userPosts.splice(index, 1);
      }
    });
    var arr = this.form.userPosts.concat(selection);
    this.newUserDataList = this.uniqueArr(arr);
  }

  public search() {
    this.userQueryParams.current = 1;
    this.userQueryParams.size = 10;
    this.getUserList();
  }


  public handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  public handleCurrentChange(current: number) {
    this.userQueryParams.current = current;
    this.getUserList();
  }

  //获取数据源
  public async getUserList(): Promise<void> {
    //this.userDataList =[]
    const res = await this.surveyService.getSelectUserList(this.userQueryParams);
    // if(res.records.length==0){
    //   this.userDataList =[]
    // }
    const newDataList = res.records.map((item: any, index: any) => {
      return {
        ...item,

        lable: item.userName,
        employeeId: item.employeeId,
        positionLevel: item.positionLevel,
        userCode: item.userCode,
        userPost: item.userPost,
        userPostId: item.userPostId,
        email: item.email || ''
      };
    });

    //this.userDataList = this.userDataList.concat(newDataList);
    this.userDataList = newDataList;

    this.userQueryParams.total = res.total;
    this.userQueryParams.pages = res.pages;
    this.userQueryParams.size = res.size;
    this.userQueryParams.current = res.current;
    await this.checkData();
  }

  // 键入textarea
  public userListStrWrite(e: any) {
    var strLength = [], Arr_ = [];
    this.noNumshow = false;
    if (this.userIdArr == '' || this.userIdArr == null || this.userIdArr == undefined) {
      this.totalNum = 0;

    } else {
      strLength = this.userIdArr.split(',');
      Arr_ = Array.from(new Set(strLength));
      this.userIdArr = Arr_.toString();
      this.totalNum = Arr_.length;
    }
  }

  // 检测用户
  public async checkPerson(): Promise<void> {
    console.log(this.userIdArr, ' this.userIdArr');
    var data_ = {};
    data_ = {
      'usersStr': this.userIdArr
    };
    // let res: any = await this.massSet.persnSearch(data_);
    // if (res) {
    //   this.noNum = res.unFinds.length;
    //   this.useNum = res.empIds.length + res.emails.length;
    //   this.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
    //   this.noNumshow = true;
    //   this.form.userListStr = res.usersStr;
    //   if (res.unFinds.length > 0) {
    //     this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //
    //       }
    //     });
    //   } else {
    //     this.$message({
    //       type: 'info',
    //       message: '用户检测完成'
    //     });
    //   }
    // }

  }

  /**
   * -END- CALL SERVICE
   */
}
