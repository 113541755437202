






















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SurveyService } from '@/services/survey-service';

@Component({
  components: {}
})

export default class addClassify extends Vue {
  @Prop({ default: true }) private title!: String;
  @Prop({ default: '' }) private chooseUserId!: String;
  @Prop({ default: true }) private agentId!: String;
  @Prop({ default: true }) private userFormVisible!: Boolean;
  @Prop({ default: true }) private userPosts!: any;
  public aa: any = [];
  public selectedAccount: any = [];
  public labelPosition: String = 'top';
  public multipleSelection: any = [];
  public selectRow: any = Object;
  public queryParams: any = { //查询参数

    userPostName: '',
    pages: 1,
    size: 5,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  private applications: any[] = [];

  @Inject(SurveyService) private SurveyService!: SurveyService;

  @Watch('chooseUserId', { immediate: true, deep: true })
  onChangeValue(newVal: any, oldVal: any) {

    //console.log(newVal, oldVal, 'Value');
  }

  public async created(): Promise<void> {
    console.log(this.userPosts, 'created');
    await this.getList();
  }

  public getRowKeys(row: any) {
    return row.userPost;
  }

  //默认选中
  public checkData() {
    this.$nextTick(() => {
      this.tableData.forEach(row => {
        let indexs = JSON.stringify(this.userPosts).indexOf(row.userPost);
        if (indexs != -1) { //不等于-1 表示存在
          const checkRef = (this.$refs.multipleTable as any);
          checkRef.toggleRowSelection(row, true);
        }
      });
    });
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.queryParams.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    this.queryParams.current = current;
    this.getList();
  }

  public search() {
    this.queryParams.current = 1;
    this.queryParams.size = 5;
    this.getList();
  }

  public handleSelect(selection: any, row: any) {
   // console.log('选中的行数据：', selection);
   // console.log('点击选中的行：', row);
   // this.selectRow= row
    //console.log(this.aa,'aa')

    // const uniqueArray = Object.values(this.aa.reduce((acc:any, obj:any) => {
    //   acc[obj.userPost] = obj;
    //   return acc;
    // }, {}));
    //
    // console.log(uniqueArray,"uniqueArray");
   // console.log(this.userPosts, '带过来的userPosts');


    // 在这里可以处理选中行的数据
  }





  public handleSelectionChange(val: any) {
    // const oldList = this.multipleSelection
    //
    this.multipleSelection = val;

    console.log(this.multipleSelection,'当前选中行')
  }

  //获取数据源
  public async getList(): Promise<void> {

    const res = await this.SurveyService.getSelectUserList(this.queryParams);
    console.log(res, 'res');
    this.tableData = res.records;
    this.checkData();
    this.queryParams.total = res.total;
    this.queryParams.pages = res.pages;
    this.queryParams.size = res.size;
    this.queryParams.current = res.current;
  }

  //保存
  @Emit('userSelectChange')
  public submitForm(): void {
    console.log(this.multipleSelection, 'this.multipleSelection');
    console.log(this.userPosts, 'userPosts');
    return this.multipleSelection;
  }

  //返回
  @Emit('userSelectChange')
  public cancel(): string {
    return '取消';
  }

  public checkimage() {
    this.userFormVisible = true;
  }
}
